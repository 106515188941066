"use client";

import { useState, useEffect, useRef } from "react";
import { Check, ChevronDown, ChevronUp, X } from "lucide-react";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { Input } from "@/components/ui/input";

interface TagFilterProps {
  tags?: { id: string; name: string }[];
  onFilterChange: (selectedTags: string[]) => Promise<boolean>;
}

export default function TagFilter({
  tags = [],
  onFilterChange,
}: TagFilterProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleTag = async (tagId: string) => {
    let tags = selectedTags.includes(tagId)
      ? selectedTags.filter((t) => t !== tagId)
      : [...selectedTags, tagId];
    let response = await onFilterChange(tags);
    if (response) setSelectedTags(tags);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const removeTag = (tagId: string) => {
    setSelectedTags((prev) => prev.filter((t) => t !== tagId));
  };

  const filteredTags = tags.filter((tag) =>
    tag.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <div className="relative mb-4 w-1/2 space-y-2" ref={dropdownRef}>
      <Button
        variant="outline"
        onClick={() => setIsOpen(!isOpen)}
        className="w-full justify-between"
      >
        {selectedTags.length > 0
          ? `${selectedTags.length} tag${selectedTags.length > 1 ? "s" : ""} selected`
          : "Select tags"}
        {isOpen ? (
          <ChevronUp className="ml-2 h-4 w-4" />
        ) : (
          <ChevronDown className="ml-2 h-4 w-4" />
        )}
      </Button>
      {isOpen && (
        <div className="absolute z-10 mt-1 w-full rounded-md border bg-background shadow-lg">
          <div className="p-2">
            <Input
              type="text"
              placeholder="Search tags..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="mb-2"
            />
            <div className="max-h-60 overflow-auto">
              {filteredTags.map((tag) => (
                <div
                  key={tag.id}
                  className="flex cursor-pointer items-center justify-between rounded-md px-2 py-1 hover:bg-accent"
                  onClick={() => toggleTag(tag.id)}
                >
                  <span>{tag.name}</span>
                  {selectedTags.includes(tag.id) && (
                    <Check className="h-4 w-4 text-primary" />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-wrap gap-2">
        {selectedTags.map((tagId) => {
          const tag = tags.find((t) => t.id === tagId);
          return tag ? (
            <Badge
              key={tag.id}
              variant="secondary"
              className="cursor-pointer"
              onClick={() => removeTag(tag.id)}
            >
              {tag.name}
              <X className="ml-1 h-3 w-3" />
            </Badge>
          ) : null;
        })}
      </div>
    </div>
  );
}
