"use client";

import { useState } from "react";
import { getAllPosts, getPostsWithTags } from "@/lib/siteFetchers";
import BlogThumbnail from "@/components/blog-thumbnail";
import ThumbnailSkeleton from "./thumbnail-skeleton";
import TagFilter from "./tag-filter";
import { PostWithTags, Tag } from "@/lib/schema";
import { toast } from "sonner";

interface PostListProps {
  initialPosts: PostWithTags[];
  tags: { id: string; name: string }[];
}

export default function PostList({ initialPosts, tags }: PostListProps) {
  const [posts, setPosts] = useState(initialPosts);
  const [isLoading, setIsLoading] = useState(false);

  const handleTagFilter = async (tags: string[]): Promise<boolean> => {
    try {
      setIsLoading(true);

      let filteredPosts;
      if (tags.length > 0) {
        filteredPosts = await getPostsWithTags(tags);
      } else {
        filteredPosts = await getAllPosts();
      }

      if (filteredPosts && filteredPosts.length > 0) {
        setPosts(filteredPosts);
      } else {
        toast.warning("No posts found with the selected tags");
      }
      setIsLoading(false);

      return true;
    } catch (error) {
      toast.error("Uh oh! Error fetching posts");
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <TagFilter tags={tags} onFilterChange={handleTagFilter} />
      {isLoading ? (
        <>
          <ThumbnailSkeleton />
          <ThumbnailSkeleton />
          <ThumbnailSkeleton />
        </>
      ) : (
        posts.map((post) => <BlogThumbnail key={post.slug} data={post} />)
      )}
    </div>
  );
}
