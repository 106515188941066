import Link from "next/link";
import BlurImage from "./blur-image";
import { placeholderBlurhash, toDateString } from "@/lib/utils";
import type { PostWithTags } from "@/lib/schema";

interface BlogThumbnailProps {
  data: PostWithTags & { subdomain?: string };
}

export default function BlogThumbnail({ data }: BlogThumbnailProps) {
  const link = data?.subdomain
    ? `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}` === "localhost:3000"
      ? `http://${data.subdomain}.localhost:3000/${data.slug}`
      : `https://${data.subdomain}.${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/${data.slug}`
    : `/${data.slug}`;
  return (
    <Link href={link}>
      <div className="flex items-center space-x-4 py-4">
        <BlurImage
          src={data.image!}
          alt={data.title ?? "Blog Post"}
          width={150}
          height={100}
          className="h-24 w-36 rounded object-cover"
          placeholder="blur"
          blurDataURL={data.imageBlurhash ?? placeholderBlurhash}
        />
        <div className="flex-1">
          <h3 className="mb-1 text-lg font-semibold dark:text-white">
            {data.title}
          </h3>
          <p className="mb-1 line-clamp-2 text-sm text-gray-600 dark:text-gray-300">
            {data.description}
          </p>
          <p className="text-xs text-gray-500 dark:text-gray-400">
            {toDateString(data.createdAt)}
          </p>
          {data.tags && data.tags.length > 0 && (
            <div className="mt-2 flex flex-wrap gap-2">
              {data.tags.map((tag) => (
                <span
                  key={tag.id}
                  className="inline-block rounded-full bg-gray-200 px-2 py-1 text-xs font-semibold text-gray-700"
                >
                  {tag.name}
                </span>
              ))}
            </div>
          )}
        </div>
      </div>
    </Link>
  );
}
